const funFacts = [
  {
    choice1: "Hond",
    choice2: "Kat",
    myChoice: "Hond",
    id: "firstTextfield",
  },
  {
    choice1: "Koffie",
    choice2: "Thee",
    myChoice: "Thee",
    id: "secondTextfield",
  },
  {
    choice1: "Uitslapen",
    choice2: "Vroeg opstaan",
    myChoice: "Vroeg opstaan",
    id: "thirdTextfield",
  },
  {
    choice1: "Winter",
    choice2: "Zomer",
    myChoice: "Winter",
    id: "fourthTextfield",
  },
  {
    choice1: "Eten bestellen",
    choice2: "Uiteten gaan",
    myChoice: "Eten bestellen",
    id: "fifthTextfield",
  },
];

export default funFacts;
