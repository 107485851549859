import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import afb_code from "../afbeeldingen/afb_code.png";
import code_banner from "../afbeeldingen/BannerCode/code.png";

const Code = (props) => {
  const projects = props.stateData;
  const codeProjects = projects
    .filter((project) => project.category === "Code")
    .map((project) => (
      <Col xs={12} md={6} lg={4} key={project.name} className="mb-5">
        <div className="werk centered">
          <img src={project.image} alt="Screen shot van project" width="100%" />
          <div className="overlay dark_bg">
            <h5 className="title_h5">{project.category}</h5>
            <a href={`/${project.slug}`} className="link_projects">
              Bekijk project
            </a>
          </div>
        </div>
      </Col>
    ));

  return (
    <div>
      <section className="animation_banner">
        <Row>
          <Col>
            <h1 className="banner_title">Code</h1>
          </Col>
        </Row>
        <img
          src={code_banner}
          alt="de banner van de overmij pagina"
          className="animation_banner_img"
        />
      </section>
      <Container fluid>
        <Row className="d-flex align-items-center margin_section">
          <Col xs={12} md={5} className="d-flex justify-content-center">
            <img
              src={afb_code}
              alt="afbeelding van code"
              width="275px"
              height="275px"
            />
          </Col>
          <Col xs={12} md={6} className="mt-5 mt-md-0">
            <p className="justify">
              Naast mijn voorliefde voor vormgeving heb ik ook een voorliefde
              voor het leren van nieuwe dingen. Ik ben dan ook constant op zoek
              naar manieren om mezelf te blijven ontwikkelen. Een van de beste
              manieren om veel te leren is door nieuwe dingen te proberen. Ik
              heb daarom een uitdagend omscholingstraject gevolgd bij Winc
              Academy tot Front-End Developer. Het programmeren maakt mij enorm
              enthousiast omdat het voor mij de perfecte combinatie is tussen
              creativiteit en analytisch denken!
            </p>
          </Col>
        </Row>
        <Row className="margin_section">{codeProjects}</Row>
      </Container>
    </div>
  );
};
export default Code;
