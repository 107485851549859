import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../afbeeldingen/Logo_Codevector.svg";
import hamburgerMenu from "../afbeeldingen/Hamburger-menu.png";
import hamburgerMenuSluiten from "../afbeeldingen/Hamburger-menu-sluiten.png";
import potlood from "../afbeeldingen/Potlood.png";
import code_haakjes from "../afbeeldingen/code-haakjes.png";

const Navbar = () => {
  const showMenu = () => {
    const menu = document.getElementById("menu-links");
    const hamburgerMenu = document.getElementById("hamburger-image");
    const hamburgerSluiten = document.getElementById("hamburger-sluiten");
    if (menu.classList.contains("d-none")) {
      menu.classList.replace("d-none", "d-flex");
      hamburgerMenu.classList.replace("d-block", "d-none");
      hamburgerSluiten.classList.replace("d-none", "d-block");
    } else {
      menu.classList.replace("d-flex", "d-none");
      hamburgerMenu.classList.replace("d-none", "d-block");
      hamburgerSluiten.classList.replace("d-block", "d-none");
    }
  };

  return (
    <Container fluid>
      <Row className="d-flex align-items-center">
        <Col xs={2} className="d-flex">
          <ul className="nav">
            <li className="logo">
              <Link className="logo-link" to="/">
                <img src={logo} alt="logo Codevector" width="194" height="62" />
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={{ span: 1, offset: 8 }} className="d-md-none">
          <button
            className="hamburger"
            onClick={(event) => showMenu(event.target)}
          >
            <img
              src={hamburgerMenu}
              alt="hamburger menu"
              height="25"
              widt="25"
              id="hamburger-image"
              className="d-block"
            />
            <img
              src={hamburgerMenuSluiten}
              alt="hamburger menu"
              height="25"
              widt="25"
              id="hamburger-sluiten"
              className="d-none"
            />
          </button>
        </Col>
        <Col
          xs={{ span: 12, offset: 0 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 4 }}
          className="d-none d-md-flex justify-content-md-end mt-5 mt-md-0"
          id="menu-links"
        >
          <ul className="nav menu-links">
            <li className="over-mij">
              <Link className="over-mij-link" to="/over-mij">
                Over mij
              </Link>
            </li>
            <li className="design">
              <Link className="design-link" to="/design">
                Design{" "}
                <img
                  src={potlood}
                  alt="potlood uit het logo van CodeVector"
                  height="28"
                  width="11"
                />
              </Link>
            </li>
            <li className="code">
              <Link className="code-link" to="/code">
                Code{" "}
                <img
                  src={code_haakjes}
                  alt="Haakjes uit het logo van CodeVector"
                  height="15"
                  width="18.5"
                />
              </Link>
            </li>
            <li className="contact">
              <Link className="contact-link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Navbar;
