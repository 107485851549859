import eindopdr_Winc from "../afbeeldingen/Projecten/Winc_Eindopdracht.png";
import kerstkaart_2019 from "../afbeeldingen/Projecten/Kerstkaart2019.jpg";
import kerstkaart_2019_heel from "../afbeeldingen/Projecten/Kerstkaart2019_heel.png";
import eindopdrW5_Winc from "../afbeeldingen/Projecten/Winc_EindopdrachtW5.png";
import menu from "../afbeeldingen/Projecten/Menukaart.png";
import menu_heel from "../afbeeldingen/Projecten/Menukaart_heel.png";
import portret_illustratie from "../afbeeldingen/Projecten/Portret_illustratie.png";
import portret_illustratie_heel from "../afbeeldingen/Projecten/Portret_illustratie_heel.png";
import kinderposter_luchtballon from "../afbeeldingen/Projecten/Kinderposter_luchtballon.png";
import kinderposter_luchtballon_heel from "../afbeeldingen/Projecten/Kinderposter_luchtballon_heel.png";

const data = [
  {
    category: "Code",
    image: eindopdr_Winc,
    name: "Eindopdracht WINC Academy",
    slug: "eindopdracht-winc-academy",
    href_GitHub: "https://github.com/CindyLooijen/WINC_Eindopdracht",
    href_live: "https://friendly-albattani-9cf1ae.netlify.app",
    recent: true,
    description:
      "De eindopdracht voor WINC Academy. Een dashboard waarmee gemakkelijker inzicht te verkrijgen is in de scores die studenten hebben gegeven aan verschillede opdrachten. Voor dit project heb ik gebruik gemaakt van HTML, CSS en REACT",
  },
  {
    category: "Design",
    image: kerstkaart_2019,
    name: "Kerstkaart 2019",
    slug: "kerstkaart-2019",
    whole_image: kerstkaart_2019_heel,
    recent: true,
    description: "Het ontwerp voor een kerstkaart",
  },
  {
    category: "Code",
    image: eindopdrW5_Winc,
    name: "Eindopdracht Week 5 WINC Academy",
    slug: "eindopdrachtW5-winc-academy",
    href_GitHub: "https://github.com/CindyLooijen/WINC_Eindopdracht_Week5",
    href_live: "https://wizardly-shaw-1bfaf4.netlify.app",
    recent: true,
    description:
      "De eindopdracht uit de een na laatste week van WINC Academy. Een app waarmee een playlist gemaakt kan worden. Liedjes kunnen een genre en een rating meekrijgen en er kan op verschillende manieren gefilterd worden. Voor dit project heb ik gebruik gemaakt van HTML, CSS, REACT en Firebase API",
  },
  {
    category: "Design",
    image: menu,
    name: "Menukaart bruiloft",
    slug: "menukaart-bruiloft",
    whole_image: menu_heel,
    recent: false,
    description:
      "Het ontwerp voor een menukaart voor een bruiloft met als thema het strand",
  },
  {
    category: "Design",
    image: portret_illustratie,
    name: "Portret illustratie",
    slug: "portret-illustratie",
    whole_image: portret_illustratie_heel,
    recent: false,
    description: "Een portret illustratie",
  },
  {
    category: "Design",
    image: kinderposter_luchtballon,
    name: "Kinderposter luchtballon",
    slug: "kinderposter-luchtballon",
    whole_image: kinderposter_luchtballon_heel,
    recent: false,
    description:
      "Een kinderposter met dieren in een luchtballon en een zelf geschreven gedicht.",
  },
];

export default data;
