import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import afb_design from "../afbeeldingen/afb_design.png";
import design_banner from "../afbeeldingen/BannerDesign/design.png";

const Design = (props) => {
  const projects = props.stateData;
  const designProjects = projects
    .filter((project) => project.category === "Design")
    .map((project) => (
      <Col xs={12} md={6} lg={4} key={project.name} className="mb-5">
        <div className="werk centered">
          <img src={project.image} alt="Screen shot van project" width="100%" />
          <div className="overlay dark_bg">
            <h5 className="title_h5">{project.category}</h5>
            <a href={`/${project.slug}`} className="link_projects">
              Bekijk project
            </a>
          </div>
        </div>
      </Col>
    ));

  return (
    <div>
      <section className="animation_banner">
        <Row>
          <Col>
            <h1 className="banner_title">Design</h1>
          </Col>
        </Row>
        <img
          src={design_banner}
          alt="de banner van de overmij pagina"
          className="animation_banner_img"
        />
      </section>
      <Container fluid>
        <Row className="d-flex align-items-center margin_section">
          <Col xs={12} md={5} className="d-flex justify-content-center">
            <img
              src={afb_design}
              alt="afbeelding van code"
              width="275px"
              height="275px"
            />
          </Col>
          <Col xs={12} md={6} className="mt-5 mt-md-0">
            <p className="justify">
              De voorliefde voor vormgeving is al op jonge leeftijd ontstaan.
              Als kind was ik al veel aan het tekenen, schilderen en knutselen.
              Na het behalen van mijn diploma aan de opleiding Media, Kunst,
              Design en Architectuur aan de Vrije Universiteit in Amsterdam,
              stond ik te springen om me praktisch verder te ontwikkelen. Ik ben
              daarom begonnen aan de opleiding Communication and
              Multimediadesign aan de LOI. Hier ben ik voor het eerst in
              aanraking gekomen met verschillende Adobe programma's waaronder
              Illustrator, Indesign, Photoshop en Animate en verliefd geworden
              op het digitaal ontwerpen.
            </p>
          </Col>
        </Row>
        <Row className="margin_section">{designProjects}</Row>
      </Container>
    </div>
  );
};

export default Design;
