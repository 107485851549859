import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Instagram, Facebook, LinkedIn, GitHub } from "@material-ui/icons";

const Footer = () => {
  return (
    <Container fluid>
      <Row>
        <Col
          xs={12}
          md={3}
          className="d-flex justify-content-center align-items-center py-3"
        >
          <a
            href="https://www.instagram.com/codevectornl/"
            target="_blank"
            rel="noopener noreferrer"
            className="socials"
          >
            <Instagram className="mx-1" style={{ fontSize: 46 }} />
          </a>
          <a
            href="https://www.facebook.com/codevectornl/"
            target="_blank"
            rel="noopener noreferrer"
            className="socials"
          >
            <Facebook className="mx-1" style={{ fontSize: 50 }} />
          </a>
          <a
            href="https://www.linkedin.com/in/cindylooijen/"
            target="_blank"
            rel="noopener noreferrer"
            className="socials"
          >
            <LinkedIn className="mx-1" style={{ fontSize: 50 }} />
          </a>
          <a
            href="https://github.com/CindyLooijen"
            target="_blank"
            rel="noopener noreferrer"
            className="socials"
          >
            <GitHub className="mx-1" style={{ fontSize: 40 }} />
          </a>
        </Col>

        <Col
          xs={{ span: 12 }}
          md={{ span: 3, offset: 2 }}
          className="d-flex flex-column justify-content-center align-items-center py-3"
        >
          <a className="email-footer" href="mailto:info@codevector.nl">
            info@codevector.nl
          </a>
        </Col>
        <Col
          xs={{ span: 12 }}
          md={{ span: 3, offset: 1 }}
          className="d-flex flex-column justify-content-center align-items-center py-3"
        >
          <p>Copyright &copy; 2020 CodeVector</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
