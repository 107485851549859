import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MapContainer, TileLayer } from "react-leaflet";
import { Instagram, Facebook, LinkedIn, GitHub } from "@material-ui/icons";

import contact_banner from "../afbeeldingen/BannerContact/contact.png";

const Contact = (props) => {
  return (
    <div>
      <section className="animation_banner">
        <Row>
          <Col>
            <h1 className="banner_title">Contact</h1>
          </Col>
        </Row>
        <img
          src={contact_banner}
          alt="de banner van de contact pagina"
          className="animation_banner_img"
        />
      </section>
      <Container fluid>
        <Row className="margin_section mb-5">
          <Col
            xs={12}
            lg={5}
            className="d-flex justify-content-center align-items-center"
          >
            <MapContainer
              id="map"
              center={[52.246516, 4.838731]}
              zoom={16}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          </Col>
          <Col
            xs={12}
            lg={7}
            className="mt-5 mt-lg-0 d-flex justify-content-center"
          >
            <div className="contactformulier">
              <p className="centered">
                Leuk dat je contact met me wilt opnemen! Je kunt hiervoor een
                mail sturen naar:
                <br></br>
                <br></br>
                <a className="email-link" href="mailto:info@codevector.nl">
                  info@codevector.nl
                </a>
              </p>
              <br></br>
              <p className="centered">
                of stuur me een berichtje via een van de onderstaande kanalen:
                <br></br>
                <br></br>
                <a
                  href="https://www.instagram.com/codevectornl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials_contact"
                >
                  <Instagram className="mx-1" style={{ fontSize: 46 }} />
                </a>
                <a
                  href="https://www.facebook.com/codevectornl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials_contact"
                >
                  <Facebook className="mx-1" style={{ fontSize: 50 }} />
                </a>
                <a
                  href="https://www.linkedin.com/in/cindylooijen/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials_contact"
                >
                  <LinkedIn className="mx-1" style={{ fontSize: 50 }} />
                </a>
                <a
                  href="https://github.com/CindyLooijen"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials_contact"
                >
                  <GitHub className="mx-1" style={{ fontSize: 40 }} />
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
