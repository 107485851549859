import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Projectspage = (props) => {
  const buttons =
    props.state.category === "Code" ? (
      <Row>
        <Col
          xs={12}
          md={6}
          lg={{ span: 3, offset: 2 }}
          className="centered my-5 links"
        >
          <a href={props.state.href_GitHub} className="links_sites">
            Bekijk op GitHub
          </a>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={{ span: 3, offset: 2 }}
          className="centered my-5 links"
        >
          <a href={props.state.href_live} className="links_sites">
            Bekijk live
          </a>
        </Col>
      </Row>
    ) : (
      <Row>
        <Col xs={12} className="centered my-5">
          <a href={props.state.whole_image} className="links_sites">
            Bekijk de hele afbeelding
          </a>
        </Col>
      </Row>
    );

  return (
    <div>
      <section className="projects_banner">
        <Row>
          <Col>
            <h1 className="h1_projects">{props.state.name}</h1>
          </Col>
        </Row>
      </section>
      <Container fluid>
        <Row className="d-flex align-items-center margin_section">
          <Col xs={12} lg={6} className="mb-5">
            <img
              src={props.state.image}
              alt="screenshot van het project"
              width="100%"
            />
          </Col>
          <Col xs={12} lg={6}>
            <p>{props.state.description}</p>
          </Col>
        </Row>
        {buttons}
      </Container>
    </div>
  );
};

export default Projectspage;
