import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import potlood from "../afbeeldingen/Potlood.png";
import code_haakjes from "../afbeeldingen/code-haakjes.png";
import banner_home_sm from "../afbeeldingen/BannerHome/banner_home_sm.png";
import banner_home_md from "../afbeeldingen/BannerHome/banner_home_md.png";
import banner_home_lg from "../afbeeldingen/BannerHome/banner_home_lg.png";
import wekelijkse_schets from "../afbeeldingen/Weekly Sketch/Week49_2020.png";

const Home = (props) => {
  const projects = props.stateData;
  const recentProjects = projects
    .filter((project) => project.recent === true)
    .map((project) => (
      <Col xs={12} md={6} lg={4} key={project.name} className="mb-5">
        <div className="werk centered">
          <img src={project.image} alt="Screen shot van project" width="100%" />
          <div className="overlay dark_bg">
            <h5 className="title_h5">{project.category}</h5>
            <a href={`/${project.slug}`} className="link_projects">
              Bekijk project
            </a>
          </div>
        </div>
      </Col>
    ));

  return (
    <Container fluid>
      <section className="home_banner">
        <Row className="mt-3 d-block d-md-none">
          <img
            src={banner_home_sm}
            alt="banner met foto en design"
            width="100%"
          />
        </Row>
        <Row className="mt-3 d-none d-md-block d-lg-none">
          <img
            src={banner_home_md}
            alt="banner met foto en design"
            width="100%"
          />
        </Row>
        <Row className="mt-3 d-none d-lg-block">
          <img
            src={banner_home_lg}
            alt="banner met foto en design"
            width="100%"
          />
        </Row>
      </section>
      <section className="wie">
        <Row className="margin_section">
          <Col xs={12} className="d-flex justify-content-center">
            <h2 className="section_title">Wie?</h2>
          </Col>
          <Col
            xs={12}
            lg={{ span: 10, offset: 1 }}
            className="d-flex justify-content-center"
          >
            <p className="centered">
              Hi! Leuk dat je een kijkje neemt op mijn website! <br></br>Ik ben
              Cindy, een creatieve beelddenker die nieuwe uitdagingen niet uit
              de weg gaat. <br></br>Kijk gerust even rond op de website om mij
              en mijn werk wat beter te leren kennen.
            </p>
          </Col>
        </Row>
      </section>
      <section className="wat">
        <Row className="purple_bg margin_section py-3">
          <Col xs={12} className="d-flex justify-content-center">
            <h2 className="section_title">Wat?</h2>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={{ span: 4, offset: 1 }}
            className="d-flex flex-column align-items-center pt-4"
          >
            <h5 className="title_h5">
              Design{" "}
              <img
                src={potlood}
                alt="potlood uit het logo van CodeVector"
                height="28"
                width="11"
              />
            </h5>
            <p className="justify">
              De voorliefde voor vormgeving is al op jonge leeftijd ontstaan.
              Als kind was ik al veel aan het tekenen, schilderen en knutselen.
              Na het behalen van mijn diploma aan de opleiding Media, Kunst,
              Design en Architectuur aan de Vrije Universiteit in Amsterdam,
              stond ik te springen om me praktisch verder te ontwikkelen. Ik ben
              daarom begonnen aan de opleiding Communication and
              Multimediadesign aan de LOI. Hier ben ik voor het eerst in
              aanraking gekomen met verschillende Adobe programma's waaronder
              Illustrator, Indesign, Photoshop en Animate en verliefd geworden
              op het digitaal ontwerpen.
            </p>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={{ span: 4, offset: 2 }}
            className="d-flex flex-column align-items-center pt-4"
          >
            <h5 className="title_h5">
              Code{" "}
              <img
                src={code_haakjes}
                alt="Haakjes uit het logo van CodeVector"
                height="15"
                width="18.5"
              />
            </h5>
            <p className="justify">
              Naast mijn voorliefde voor vormgeving heb ik ook een voorliefde
              voor het leren van nieuwe dingen. Ik ben dan ook constant op zoek
              naar manieren om mezelf te blijven ontwikkelen. Een van de beste
              manieren om veel te leren is door nieuwe dingen te proberen. Ik
              heb daarom een uitdagend omscholingstraject gevolgd bij Winc
              Academy tot Front-End Developer. Het programmeren maakt mij enorm
              enthousiast omdat het voor mij de perfecte combinatie is tussen
              creativiteit en analytisch denken!
            </p>
          </Col>
        </Row>
      </section>
      <section className="recent-werk">
        <Row className="margin_section projecten_mb">
          <Col xs={12} className="d-flex justify-content-center">
            <h2 className="section_title">Recent werk</h2>
          </Col>
          {recentProjects}
        </Row>
      </section>
      <section className="weekly-sketch">
        <Row className="mb-5">
          <Col xs={12} className="d-flex justify-content-center">
            <h2 className="section_title">Weekly sketch</h2>
          </Col>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 4, offset: 4 }}
            className="wekelijkse_schets mb-5 d-flex justify-content-center"
          >
            <img
              src={wekelijkse_schets}
              alt="wekelijkse schets"
              width="100%"
              height="100%"
            />
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default Home;
