import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ExpandLess } from "@material-ui/icons";
import data from "../src/Data/projects_data";
import funFacts from "../src/Data/fun_facts";
import SmoothScroll from "smooth-scroll";

import Navbar from "../src/Components/Navbar";
import Home from "../src/Components/Home";
import OverMij from "../src/Components/OverMij";
import Design from "../src/Components/Design";
import Code from "../src/Components/Code";
import Contact from "../src/Components/Contact";
import Footer from "../src/Components/Footer";
import Projectpage from "../src/Components/Projects";

const App = () => {
  const [stateData] = useState(data);
  const [stateFunFacts] = useState(funFacts);
  const [stateForm, setStateForm] = useState({});

  const projects = stateData.map((project) => (
    <Route path={`/${project.slug}`} key={project.name}>
      <Projectpage state={project} />
    </Route>
  ));

  const scrollToTop = new SmoothScroll(".to-top");

  return (
    <div className="wrapper-page" id="top">
      <Router>
        <div className="header">
          <Navbar />
        </div>
        <div className="main">
          <Switch>
            {projects}
            <Route path="/over-mij">
              <OverMij stateFunFacts={stateFunFacts} />
            </Route>
            <Route path="/design">
              <Design stateData={stateData} />
            </Route>
            <Route path="/code">
              <Code stateData={stateData} />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home stateData={stateData} />
            </Route>
          </Switch>
        </div>
        <div className="footer">
          <div className="back-to-top">
            <a href="#top" className="to-top socials">
              <ExpandLess style={{ fontSize: 45 }} />
            </a>
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default App;
