import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import profielfoto from "../afbeeldingen/Profielfoto/profielfoto_rond.png";
import overmij_banner from "../afbeeldingen/BannerOverMij/overmij.png";
import smiley_neutral from "../afbeeldingen/Smiley/Smiley_neutral.png";
import smiley_happy from "../afbeeldingen/Smiley/Smiley_happy.png";
import smiley_unhappy from "../afbeeldingen/Smiley/Smiley_unhappy.png";
import smiley_rightarm from "../afbeeldingen/Smiley/Smiley_rechterarm.png";
import smiley_leftarm from "../afbeeldingen/Smiley/Smiley_linkerarm.png";
import smiley_rightarm_happy from "../afbeeldingen/Smiley/Smiley_rechterarm_blij.png";
import smiley_leftarm_happy from "../afbeeldingen/Smiley/Smiley_linkerarm_blij.png";
import smiley_rightarm_unhappy from "../afbeeldingen/Smiley/Smiley_rechterarm_unhappy.png";
import smiley_leftarm_unhappy from "../afbeeldingen/Smiley/Smiley_linkerarm_unhappy.png";
import question_mark from "../afbeeldingen/Smiley/vraagteken.png";
import heart from "../afbeeldingen/Smiley/hartje.png";
import broken_heart from "../afbeeldingen/Smiley/gebroken_hart.png";

const OverMij = (props) => {
  const onclickFunFacts = (eventId, eventClassName, eventName) => {
    const smiley = document.getElementById("smiley");
    const rightArm = document.getElementById("right-arm");
    const leftArm = document.getElementById("left-arm");
    const questionMark = document.getElementById("question-mark");
    const questionMark2 = document.getElementById("question-mark2");

    switch (eventName) {
      case "firstTextfield":
        eventId === eventClassName
          ? (smiley.src = smiley_happy)
          : (smiley.src = smiley_unhappy);
        break;
      case "secondTextfield":
        eventId === eventClassName
          ? (rightArm.src = smiley_rightarm_happy)
          : (rightArm.src = smiley_rightarm_unhappy);
        break;
      case "thirdTextfield":
        eventId === eventClassName
          ? (leftArm.src = smiley_leftarm_happy)
          : (leftArm.src = smiley_leftarm_unhappy);
        break;
      case "fourthTextfield":
        eventId === eventClassName
          ? (questionMark.src = heart)
          : (questionMark.src = broken_heart);
        break;
      case "fifthTextfield":
        eventId === eventClassName
          ? (questionMark2.src = heart)
          : (questionMark2.src = broken_heart);
    }
  };

  const funFacts = props.stateFunFacts;
  const funFactsSection = funFacts.map((funFact, key) => (
    <Row key={key}>
      <Col
        xs={12}
        md={{ span: 6, offset: 3 }}
        className="d-flex justify-content-center align-items-center mb-4"
      >
        <div>
          <input
            type="radio"
            name={funFact.id}
            id={funFact.choice1}
            className={funFact.myChoice}
            onClick={(event) =>
              onclickFunFacts(
                event.target.id,
                event.target.className,
                event.target.name
              )
            }
          />
          <label className="funFacts_choices" htmlFor={funFact.choice1}>
            {funFact.choice1}
          </label>
        </div>
        <div>
          <input
            type="radio"
            name={funFact.id}
            id={funFact.choice2}
            className={funFact.myChoice}
            onClick={(event) =>
              onclickFunFacts(
                event.target.id,
                event.target.className,
                event.target.name
              )
            }
          />
          <label className="funFacts_choices" htmlFor={funFact.choice2}>
            {funFact.choice2}
          </label>
        </div>
      </Col>
    </Row>
  ));

  return (
    <div>
      <section className="animation_banner">
        <Row>
          <Col>
            <h1 className="banner_title">Over mij</h1>
          </Col>
        </Row>
        <img
          src={overmij_banner}
          alt="de banner van de overmij pagina"
          className="animation_banner_img"
        />
      </section>
      <Container fluid>
        <section className="introductie">
          <Row className="d-flex align-items-center margin_section">
            <Col xs={12} md={5} className="d-flex justify-content-center">
              <img
                src={profielfoto}
                alt="profielfoto"
                width="275px"
                height="275px"
              />
            </Col>
            <Col xs={12} md={6} className="mt-5 mt-md-0">
              <p className="justify">
                Ik ben Cindy, een creatieve beelddenker die nieuwe uitdagingen
                niet uit de weg gaat. Ik ben enorm gedreven, leergierig en
                constant op zoek naar manieren om mezelf te blijven ontwikkelen.
                Wanneer ik geen potlood in mijn handen heb en niet achter de
                computer zit, dan ben ik waarschijnlijk op het hockeyveld te
                vinden, aan het koken of bakken, aan het werk in mijn (moes)tuin
                of een heerlijke wandeling aan het maken in de natuur met mijn
                hond. En... ik heb een enorme zwak voor Beagles en spelletjes!
              </p>
            </Col>
          </Row>
        </section>
        <section className="vaardigheden">
          <Row className="margin_section border_top">
            <Col xs={12} className="d-flex justify-content-center">
              <h2 className="section_title">Vaardigheden</h2>
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 6, offset: 3 }}
              className="d-flex flex-column align-items-center"
            >
              <div className="skills mb-2">
                <p className="skill">Illustrator</p>
                <div className="bar-bg">
                  <div className="bar illustrator">80%</div>
                </div>
              </div>
              <div className="skills mb-2">
                <p className="skill">Indesign</p>
                <div className="bar-bg">
                  <div className="bar indesign">70%</div>
                </div>
              </div>
              <div className="skills mb-2">
                <p className="skill">Photoshop</p>
                <div className="bar-bg">
                  <div className="bar photoshop">60%</div>
                </div>
              </div>
              <div className="skills mb-2">
                <p className="skill">HTML/CSS</p>
                <div className="bar-bg">
                  <div className="bar html-css">80%</div>
                </div>
              </div>
              <div className="skills mb-2">
                <p className="skill">Javascript</p>
                <div className="bar-bg">
                  <div className="bar javascript">70%</div>
                </div>
              </div>
              <div className="skills mb-2">
                <p className="skill">React</p>
                <div className="bar-bg">
                  <div className="bar react">80%</div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="fun-facts mb-5">
          <Row className="margin_section">
            <Col xs={12} className="centered">
              <h2 className="section_title">Fun facts</h2>
              <p>
                Ik ben gek op het spelen van spelletjes! Speel je een spelletje
                met me mee? <br></br>Kies steeds een van de onderstaande opties
                en kijk of je me er blij mee maakt of niet.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <div id="smiley_parts">
                <img src={question_mark} alt="Vraagteken" id="question-mark" />
                <img src={question_mark} alt="Vraagteken" id="question-mark2" />
                <img src={smiley_neutral} alt="smiley" id="smiley" />
                <img
                  src={smiley_rightarm}
                  alt="rechterarm van smiley"
                  id="right-arm"
                />
                <img
                  src={smiley_leftarm}
                  alt="linkerarm van smiley"
                  id="left-arm"
                />
              </div>
            </Col>
          </Row>
          {funFactsSection}
        </section>
      </Container>
    </div>
  );
};

export default OverMij;
